import React, { useState, useEffect } from "react";


function CountrySelector({ onCountryChange }) {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [ipCountrySet, setIpCountrySet] = useState(false);

  useEffect(() => {
    // Определение геолокации пользователя с использованием IP-геолокации
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        const userCountryCode = data.country || ""; // Получение кода страны пользователя
        // Загрузка списка стран с API
        fetch("https://restcountries.com/v3.1/all")
          .then((response) => response.json())
          .then((data) => {
            // Обработка данных и формирование списка стран
            const countries = data.map((country) => ({
              code: country.cca2,
              label: country.name.common,
              flag: country.flag,
            }));
            // Сортировка стран по алфавиту
            countries.sort((a, b) => a.label.localeCompare(b.label));
            setCountriesList(countries);

            // Установка страны пользователя по умолчанию, если её код найден
            if (userCountryCode && !ipCountrySet) {
              const userCountry = countries.find(
                (country) => country.code === userCountryCode
              );
              if (userCountry) {
                setSelectedCountry(userCountry.code);
                onCountryChange(userCountry.code);
                setIpCountrySet(true); // Устанавливаем состояние, чтобы не изменять выбранную страну снова
              }
            } else if (!selectedCountry) {
              setSelectedCountry(countries[0].code); // Установка первой страны по умолчанию
            }
          })
          .catch((error) => {
            console.error("Ошибка при запросе данных: ", error);
          });
      })
      .catch((error) => {
        console.error("Ошибка при определении геолокации: ", error);
      });
  }, [onCountryChange, ipCountrySet, selectedCountry]);

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);
    onCountryChange(countryCode); // Вызываем функцию обратного вызова с выбранным кодом страны
  };

  return (
    <div>
      <h2>Choose your country</h2>
      <div className="relative selectdiv">
        <select
          className="rounded-md p-3"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          {countriesList.map((country) => (
            <option key={country.code} value={country.code}>
              {country.flag} {country.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default CountrySelector;
