import React, { useState } from "react";
import CountrySelector from "./CountrySelect";

function LandingPage() {
  const currentUrl = window.location.href;

  const newUrl = new URL(currentUrl);
  const searchParams = new URLSearchParams(newUrl.search);

  const id = searchParams.get("ad_campaign_id");
  const keyword = searchParams.get("keyword");

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (selectedCountryCode) => {
    setSelectedCountry(selectedCountryCode);
  };

  const handleFormSubmit = () => {
    
    if (selectedCountry) {
      // Проверяем, выбрана ли страна
      const redirectUrl = `https://link.gobig.finance/rand?ad_campaign_id=${id}&keyword=${keyword}&country=${selectedCountry}`;
      window.location.href = redirectUrl;
    } else {
      alert("Please select a country before proceeding.");
    }
  };

  return (
    <div className="">
      <form className="p-5 rounded-lg">
        <CountrySelector onCountryChange={handleCountryChange} />
        <button
          className="btn-primary rounded-md p-3 mt-3"
          type="button"
          onClick={handleFormSubmit}
        >
          Proceed to the bonus
        </button>
      </form>
      <div className="border border-1 rounded-md  p-3 mt-5 descriptionText">
        Pick the <span className="green">country of your residence</span> in the
        selector and click the button in order to proceed to your desired offer.
        Please select the correct country,{" "}
        <span className="red">
          otherwise the bonus may be not available for you!
        </span>
      </div>

      <h2 className="mt-7">More relevant offers</h2>
      <div className="flex justify-between mt-7 flex-wrap offers">
        <a href="https://link.bo-nus.com/partners_21bets_bonus" target="_blank" className="flex flex-col offer items-center">
          <img src="/images/21bets.png" alt="" />
          <p className="mt-3">21Bets - 120% up to €600, min.dep. €25</p>
        </a>
        <a href="https://link.bo-nus.com/partners_cosmicslot_bonus" target="_blank" className="flex flex-col offer items-center">
          <img src="/images/cosmicslot.png" alt="" />
          <p className="mt-3">Cosmicslot - 252% up to €3500 + 200 FS, min.dep. €20</p>
        </a>
        <a href="https://link.bo-nus.com/partners_jupi_bonus" target="_blank" className="flex flex-col offer items-center">
          <img src="/images/jupi.png" alt="" />
          <p className="mt-3">Jupi Casino - 120% up to €600, min.dep. €25</p>
        </a>
      </div>
    </div>
  );
}

export default LandingPage;
